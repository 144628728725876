<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">

    <data-view-sidebar :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" :data="sidebarData" />

    <vs-table ref="table" pagination :max-items="itemsPerPage" search :data="userHasPacks">

      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

<!--        <div class="flex flex-wrap-reverse items-center data-list-btn-container">-->
<!--          &lt;!&ndash; ADD NEW &ndash;&gt;-->
<!--          <div class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary" @click="addNewData">-->
<!--            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />-->
<!--            <span class="ml-2 text-base text-primary">Ajouter</span>-->
<!--          </div>-->
<!--        </div>-->

        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
          <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ userHasPacks.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : userHasPacks.length }} of {{ queriedItems }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>

            <vs-dropdown-item @click="itemsPerPage=4">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="Prospect">Prospect</vs-th>
        <vs-th sort-key="name">Bénéficiare(s)</vs-th>
        <vs-th sort-key="name">Pack</vs-th>
        <vs-th sort-key="montant">Montant PACK</vs-th>
        <vs-th sort-key="numberOfPerson">Montant de la COMMISSION</vs-th>
        <vs-th sort-key="numberOfPerson">éTAT de la COMMISSION </vs-th>
        <vs-th>Action</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

          <vs-td>
            <p class="product-name font-medium truncate">{{ tr.assured.username }} </p>
          </vs-td>

          <vs-td >
            <p v-for="item in tr.recipients" :key="item.full_name" class="product-name font-medium truncate">{{ item.full_name}},</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate">{{ tr.pack.name }}</p>
          </vs-td>

          <vs-td>
            <vs-chip :color="getOrderStatusColor()" class="product-order-status">{{ new Intl.NumberFormat('de-DE').format(tr.amount ? tr.amount : tr.pack.amount) }}</vs-chip>
          </vs-td>

          <vs-td>
            <vs-chip :color="getOrderStatusColor()" class="product-order-status">{{ tr.commissionToPay }}</vs-chip>
          </vs-td>

<!--          <vs-td>-->
<!--            <p class="product-category">{{ tr.numberOfPerson ? tr.numberOfPerson : tr.pack.numberOfPerson }}</p>-->
<!--          </vs-td>-->


          <vs-td>
            <vs-chip :color="getOrderStatusColor()" class="product-order-status">{{ PayObject[ tr.commissionPaid] }}</vs-chip>
          </vs-td>
          <vs-td class="whitespace-no-wrap">
            <div class="flex">
<!--              <vx-tooltip text="Détail" color="primary">-->
<!--                <feather-icon-->
<!--                  style="color: #7367F0"-->
<!--                  icon="EyeIcon"-->
<!--                  svgClasses="w-5 h-5 hover:text-primary stroke-current"-->
<!--                  class="mr-2"-->
<!--                  @click="activePrompt2 = true" v-on:click="openPlayerDialog(tr)"-->
<!--                />-->
<!--              </vx-tooltip>-->

<!--              <vx-tooltip text="Modifier" color="warning">-->
<!--                <feather-icon style="color: #FF9F43" icon="EditIcon" svgClasses="w-5 h-5 hover:text-warning stroke-current" class="mr-2" @click="editData(tr)"/>-->
<!--              </vx-tooltip>-->
<!--              <vx-tooltip text="Supprimer" color="danger">-->
<!--                <feather-icon @click="deleteData(tr.id)" style="color: red" icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="mr-2"/></vx-tooltip>-->

              <vx-tooltip text="Payer" color="warning">
                <feather-icon v-if="tr.commissionPaid===false" style="color: #FF9F43 " icon="DollarSignIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click.stop="Pay_commissions(tr.id)" class="ml-4" />
              </vx-tooltip>
            </div>
          </vs-td>
        </vs-tr>
        </tbody>
      </template>
    </vs-table>
    <vs-prompt title="DÉTAILS DE LA COMMISSION PAYÉE" buttons-hidden @close="close" :active.sync="activePrompt2">
      <div class="con-exemple-prompt" v-if="selectedPlayer">
        <vs-input readonly label="PROSPECT"  v-model=" selectedPlayer.assured.username " class="mt-4 mb-2 w-full" />
        <vs-input readonly label="PACK "  v-model=" selectedPlayer.pack.name" class="mt-4 mb-2 w-full" />
        <vs-input readonly label="MONTANT PACK"  v-model=" selectedPlayer.amount " class="mt-4 mb-2 w-full" />
        <vs-input readonly label="COMMISSION PAYÉE"  v-model=" selectedPlayer.commissionToPay " class="mt-4 mb-2 w-full" />
<!--        <vs-input readonly label="NOMBRE DE PERSONNE"  v-model=" selectedPlayer.numberOfPerson " class="mt-4 mb-2 w-full" />-->
<!--        <vs-input v-for="item in selectedPlayer.recipients" :key="item.full_name" readonly label="BENEFICIARE "  v-model="item.full_name" class="mt-4 mb-2 w-full" />-->
      </div>
    </vs-prompt>
  </div>
</template>

<script>

const color_array = ['warning', 'success', 'danger', 'primary']
let iopi = 0

export default {

  data () {
    return {
      PayObject: {
        true: 'PAYÉE',
        false: 'IMPAYÉE'
      },
      userHasPacks: [],
      selected: [],
      bene:[],
      itemsPerPage: 4,
      isMounted: false,
      selectedPlayer:undefined,
      activePrompt2:false,
      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {}
    }
  },
  computed: {
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.userHasPacks.length
    }
  },
  methods: {
    addNewData () {
      this.sidebarData = {}
      this.toggleDataSidebar(true)
    },
    openPlayerDialog: function(player) {
      this.selectedPlayer = player;

    },
    deleteData (id) {
      const base_self = this
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Confirmation',
        text: 'Confirmer la suppression?',
        acceptText: 'Confirmer',
        cancelText: 'Annuler',
        accept: async () => {
          this.$vs.loading()
          this.$http.delete(`userHasPacks/${id}/`)
            .then(() => {
              base_self.getAllUserHasPacks()
              this.$vs.notify({
                time:4000,
                title: 'SUPPRESSION',
                text: "L'adhésion est supprimée",
                color: 'success',
                position:'top-center'
              })

            })
            .catch(() => {
              window.getPrendTaCom.error({ message: 'La suppression de l\'adhésion a échouée.' })
            })
        }
      })

    },

    Pay_commissions (id) {
      const base_self = this
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Confirmation',
        text: 'Confirmer le paiement?',
        acceptText: 'Confirmer',
        cancelText: 'Annuler',
        accept: async () => {
          this.$vs.loading()
          this.$http.put(`pay-commission/${id}/`)
            .then(() => {
              base_self.getAllUserHasPacks()
              this.$vs.notify({
                time:4000,
                title: 'PAIEMENT',
                text: "Commission payée",
                color: 'success',
                position:'top-center'
              })

            })
            .catch(() => {
              window.getPrendTaCom.error({ message: 'La suppression de l\'adhésion a échouée.' })
            })
        }
      })

    },

    // editData (data) {
    //   const sidebarData = JSON.parse(JSON.stringify(data))
    //   const recipients = []
    //
    //   for (let i = 0; i < sidebarData.recipients.length; i++) {
    //     recipients.push(sidebarData.recipients[i])
    //   }
    //
    //   sidebarData.recipients = recipients
    //   sidebarData.pack = sidebarData.pack.id
    //   sidebarData.assured = sidebarData.assured.id
    //   this.sidebarData = sidebarData
    //   this.toggleDataSidebar(true)
    // },
    getOrderStatusColor () {
      const libelle = color_array[iopi]

      if ((iopi + 1) < color_array.length) {
        iopi++
      } else {
        iopi = 0
      }
      return libelle
    },
    getPopularityColor (num) {
      if (num > 90)  return 'success'
      if (num > 70)  return 'primary'
      if (num >= 50) return 'warning'
      if (num < 50)  return 'danger'
      return 'primary'
    },
    toggleDataSidebar (val = false) {
      this.addNewDataSidebar = val
    },
    getAllUserHasPacks () {
      this.$vs.loading()
      this.$http.get('userHasPacks/')
        .then((response) => {
          this.userHasPacks = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    }
  },
  created () {
    window.getProspect = this
    this.getAllUserHasPacks()

    if (!moduleDataList.isRegistered) {
      this.$store.registerModule('dataList', moduleDataList)
      moduleDataList.isRegistered = true
    }
    this.$store.dispatch('dataList/fetchDataListItems')
  },
  mounted () {
    this.isMounted = true
  }
}
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr{
        box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
        td{
          padding: 20px;
          &:first-child{
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }
          &:last-child{
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }
        td.td-check{
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead{
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text{
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check{
        padding: 0 15px !important;
      }
      tr{
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
